<template>
  <div class="container">

    <SpacerRow />
    <ErrorRow :error="error" />
    <SuccessRow :message="message" />
  
    <div class="row">
      <div class="rc-box-col-15">
      <VehicleEdit
        :defaultValue="vehicle"
        :checkRequirements="requirementsCheck"
        :tip="domain.lang().get(catelog.VEHICLE.ADD_HOW_TO)"
        v-on:enter="done"
        v-on:value="setValue"
        v-on:save="save"
        />
      </div>
    </div>
    
    <div class="row">
      <div class="rc-box-col-3"> </div>
      <div class="rc-box-col-9">
        <div class="container">
          <SaveRow :disabled="isSaving" v-on:value="checkRequirements" />
        </div>
      </div>
      <div class="rc-box-col-3"> </div>
    </div>
  
    <SpacerRow />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VehicleEdit from '@/views/portal/vehicles/edit/VehicleEdit.vue';
import StringUtils from '@/utils/StringUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';

import ErrorRow from '@/components/row/ErrorRow.vue';
import SuccessRow from '@/components/row/SuccessRow.vue';
import SaveRow from '@/components/row/SaveRow.vue';

import Vehicle from '@/domain/model/vehicle/Vehicle.js';
import Catelog from '@/domain/session/CanadianEnglish.js';

import SpacerRow from '@/portals/shared/library/spacer/SpacerRow.vue';

export default {
  name: 'portal-vehicles-add',
  components: {
    VehicleEdit,
    ErrorRow,
    SaveRow,
    SuccessRow,
    SpacerRow,
  },
  data() {
    return {
      id: this.$route.params['id'], 
      kvps: {},
      event: {},
      message: null,
      error: null,
      isSaving: false,
      catelog: Catelog.KEYS,
      requirementsCheck: false,
    };
  },
  computed: {
    ...mapGetters([
      'vehicles_found', 
      'companys_found', 
      'types_found', 
      'users_store', 
      'makers_found', 
      'categorys_found',
      'schedules_found',
      'auth_client',
      'auth_user',
      'auth_connected',
      'domain',
      ]),
    navigator: () => navigator,
    vehicle: function() {
      return this.kvps;
    },
  },
  mounted: function() {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN });
    } else {
//      this.kvps['odometerType'] = "KM";
    }
  },
  watch: {
    auth_connected() {
      if (this.auth_connected) {
        this.send()
      }
    },
  },
  methods: {
    isEven: function(index) {
      return StringUtils.isEven(index);
    },
    setValue: function(kvp) {
      ContentUtils.set(this.kvps, kvp, this.domain.logger());
    },
    done: function() {
      //
    },
    checkRequirements: function() {
      this.requirementsCheck = true;
    },
    save: function (good) {
      if (good) {
        if (this.auth_connected) {
          this.send();
        } else {
          this.$connect();  
        }
      } else {
        this.requirementsCheck = false;
      }
    },
    send: function() {
      this.isSaving = true;
      this.error = null;
      this.message = null;
      
      var vehicle = new Vehicle(this.domain, this.kvps);
      vehicle.withStateActive();
      
      var event = this.domain.events().vehicleSave(vehicle.getData());
      event.send(this.listener);
    },
    listener: function(rcEvent) {
      if (rcEvent.error()) {
        this.error = rcEvent.error();
      } else {
        this.kvps = rcEvent.payload().valuesFor('Vehicle').data;
        this.message = "Vehicle successfully saved!";
        this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.VEHICLES });
      }
      this.isSaveDisabled = false;
      this.isSaving = false;
    }
  }  
}
</script>